import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "../layouts/layout.js";
import { RichText } from "prismic-reactjs";
import SEO from "../components/Seo";
import tw, { styled } from "twin.macro";
import Header from "../components/Header.js";
import SplitSection from "../components/SplitSection.js";
import { CutCorner, H1, H2, H3, H4, P } from "../utils/helpers.js";
import { htmlSerializer } from "../utils/htmlSerializer";
import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql";

const Mission = styled.section`
  ${tw`relative max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8 py-12`}
  & H4 {
    ${tw`mt-8 text-xl leading-9 font-semibold sm:leading-10 lg:leading-none pb-2 border-b border-ink inline px-4 text-ink uppercase tracking-wide`}
  }
  & H2 {
    ${tw`my-16 text-fire font-light text-4xl leading-tight max-w-3xl mx-auto mt-8 mb-4`}
  }
`;

const TeamSection = tw.section`
  px-0 md:px-4 lg:px-8 max-w-7xl mx-auto
`;

const TeamGrid = styled(CutCorner)`
  ${tw`p-8 text-white md:flex md:flex-row md:flex-wrap md:items-stretch mb-12`}
`;

const TeamTile = tw.div`
  flex flex-col md:w-1/2 p-2 md:p-8 mb-12 md:mb-0
`;

const TeamHeader = styled.div`
  ${tw`flex flex-row mb-6`}
  & img {
    ${tw`flex-none w-32 h-32`}
  }
  & div {
    ${tw`px-6`}
    & P {
      ${tw`text-fire`}
    }
  }
`;

const About = ({ data }) => {
  const page = get(data, "prismic.allAbout_pages.edges[0].node");
  if (!page) return null;
  // console.log(page);
  return (
    <Layout>
      <SEO title="About Us" />
      <Header bgImage="https://source.unsplash.com/pAWY7xrsLwc/1600x900">
        <H1>{RichText.asText(page.title)}</H1>
      </Header>
      <Mission>
        <H4>{RichText.asText(page.mission_title)}</H4>
        <H2>{RichText.asText(page.mission_text)}</H2>
      </Mission>
      <TeamSection>
        <TeamGrid>
          {page.team_members.map(({ name, bio, team_title, portrait }, idx) => (
            <TeamTile>
              <TeamHeader>
                <img src={portrait.url} alt="Bio" />
                <div>
                  <H4>{name}</H4>
                  <P>{team_title}</P>
                </div>
              </TeamHeader>
              <RichText render={bio} htmlSerializer={htmlSerializer} />
            </TeamTile>
          ))}
        </TeamGrid>
      </TeamSection>
      <SplitSection pos={1} img={page.careers_image.url}>
        <H3>{RichText.asText(page.careers_title)}</H3>
        <RichText
          render={page.careers_text}
          htmlSerializer={htmlSerializer}
          linkResolver={linkResolver}
        />
      </SplitSection>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query AboutQuery {
    prismic {
      allAbout_pages {
        edges {
          node {
            careers_image
            careers_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            careers_text
            careers_title
            header_image
            header_imageSharp {
              childImageSharp {
                fluid(maxWidth: 960, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mission_text
            mission_title
            title
            team_members {
              bio
              name
              portrait
              team_title
            }
          }
        }
      }
    }
  }
`;
